//.mainContent {
//
//  .row {
//    margin-bottom: 4rem;
//
//    *:last-child {
//      margin-bottom: 0;
//    }
//  }
//}
//

.mainContent {
  .row {
    margin-bottom: 0;

    &.box--high,
    &.box--low,
    &.divider {
      margin-bottom: 4rem;
    }
    //
    //*:last-child:not(.box--low, .box--high) {
    //  margin-bottom: 0;
    //}
  }
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .mainContent {
    .row {
      margin-bottom: 4rem;

      //*:last-child:not(.box--low, .box--high) {
      *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.magnific {
  position: relative;
  display: block;

  &-title {
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    z-index: 9;

    span {
      transform: translateY(20px);
      transition: 0.2s;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    .magnific-title {
      visibility: visible;
      opacity: 1;

      span {
        transform: translateY(0);
      }
    }
  }
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  right: 0 !important;
}
