label,
legend {
  display: block;
  margin-bottom: 0.2rem;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
}

input {
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    height: 4rem;
    padding: 6px 10px; // The 6px vertically centers text on FF, ignored by Webkit
    background-color: $color-background-forms;
    border: none;
    color: $color-text;
    border-radius: $global-radius;
    position: relative;
    box-shadow: none;

    &:focus {
      border: none;
      outline: 0;
    }
  }
}

textarea {
  background-color: $color-background-forms;
  border: none;
  color: $color-text;
  border-radius: $global-radius;

  &:focus {
    border: none;
    outline: 0;
  }
}

.fieldgroup {
  position: relative;
}

.mainContent {
  form .row {
    margin-bottom: 0.9rem;
  }

  textarea {
    min-height: 12rem;
  }
}
