// Skeleton Variables
// To use them uncomment the line you want to use and set your values
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Breakpoints
// $bp-larger-than-mobile    : "min-width: 400px"  ;
// $bp-larger-than-phablet   : "min-width: 550px"  ;
// $bp-larger-than-tablet    : "min-width: 750px"  ;
// $bp-larger-than-desktop   : "min-width: 1000px"  ;
// $bp-larger-than-desktophd : "min-width: 1200px"  ;

// Colors
// $light-grey: #e1e1e1;
// $dark-grey: #333;
// $primary-color: #33c3f0;
// $secondary-color: lighten($dark-grey, 13.5%);
// $border-color: #bbb;
// $link-color: #1eaedb;
// $font-color: #222;

// Typography
// $font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;

//Grid Variables
// $container-width: 960px;
// $container-width-larger-than-mobile: 85%;
// $container-width-larger-than-phablet: 80%;
// $total-columns: 12;
// $column-width: 100 / $total-columns; // calculates individual column width based off of # of columns
// $column-margin: 4%; // space between columns

// Misc
$global-radius: 0;

// END SKELETON VARS
// -------------------------------------------------
$base-spacing-unit: 10px;
$background-distance: 20px;

$container-width: 900px;
$column-margin: 2%; // space between columns
$total-columns: 6;

// $bp-larger-than-phablet   : "min-width: 750px"
$mq-breakpoints: (
  mobile:  320px,
  phablet: 550px,
  tablet:  740px,
  tabletoffset: 800px,
  desktop: 980px,
  wide:    1300px
);

// COLORS
$color-text: #000;
$color-background: #fff;
$color-background-offset: #ececec;

$color-nav-link: #000;
$color-link: #646464;
$color-link-hover: $color-text;

$color-text-light: #c4c4c4;
$color-text-highlight: #808080;

$color-background-box-light: #d8d8d8;
$color-background-box-dark: #808080;

$color-background-forms: #e6e6e6;

// FONTS
$font-family: 'Avenir Next W00', "Avenir", Helvetica, Arial, sans-serif;
$font-family-alt: 'volkhovbold_italic', "Avenir", Helvetica, Arial, serif;
$font-family-icons: 'FontAwesome';

//
$slick-font-path: "./../js/vendor/slick/fonts/";
$slick-loader-path: "./../js/vendor/slick/" !default;
