body {
  // perspective: 1000px;
}

.mainHeader {
  // height: 4rem;
  // margin-bottom: 3rem;
  // padding-top: $base-spacing-unit * 2;
  // position: relative;

  position: fixed;
  z-index: 51;
  width: 100%;
  top: 0;
  left: 0;
  padding: 3rem 4%;
  max-width: calc(900px + 8%);
  background-color: #fff;
  margin-bottom: 0;
  height: auto;

  @media (max-width: 550px) {
    padding: 1.5rem 4%;
  }

  .navigation-wrapper {
    position: relative;
    z-index: 10;
  }
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .mainHeader {
    margin-bottom: 4rem;
  }
}

.branding {
  float: none;
  height: 4rem;
  // vertical-align: middle;
  display: inline-block;
  position: relative;

  .logo {
    font-size: 2.2rem;
    font-weight: 700;
    display: inline-block;
    position: relative;
    top: 0.1em;
    // vertical-align: bottom;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $color-text-highlight;
        text-decoration: none;
      }
    }
  }
}

// .branding:after,
// .mainNav:after {
//   display: inline-block;
//   content: " ";
//   // vertical-align: middle;
//   height: 4rem;
// }

.mainNav {
  line-height: 4rem;
  // float: left;
  height: auto;
  // vertical-align: middle;
  display: none;
  position: absolute;
  width: 114%;
  left: -7%;
  background: #fff;
  border-top: 1px solid $color-text-light;
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .mainNav {
    height: 4rem;
    margin-left: 2rem;
    display: inline-block;
    position: relative;
    border: none;
    left: auto;
    width: auto;
    background: none;
  }
}

.mainNav__link {
  // color: $color-nav-link;
  font-size: 1.4rem;
  color: $color-text-light;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.13em;
  vertical-align: baseline;
  // vertical-align: middle;
  position: relative;
  transition: all 333ms cubic-bezier(0.215, 0.61, 0.355, 1);
  top: 0.23em;
  display: block;
  float: none;
  //top: auto;
  border-bottom: 1px solid $color-text-light;
  margin: 0;
  padding: 0 6%;
  background: #fff;

  &:hover {
    text-decoration: underline;
    color: $color-text-highlight;
  }

  &.active {
    color: $color-text-highlight;
  }
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .mainNav__link {
    margin: 0 0.8rem;
    display: inline-block;
    padding: 0;
    border: none;
    background: none;
  }
}

.trigger-mainNav {
  float: right;
  position: relative;
  top: 9px;
  padding: 0;
  border: none;
  text-align: right;

  &:hover {
    text-decoration: none;
  }
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .trigger-mainNav {
    display: none;
  }
}

.trigger-search {
  color: $color-text-light;
  text-decoration: none;
  transition: all 0.05s ease-in-out;
  position: absolute;
  z-index: 65;
  display: none;
  right: 0;
  height: 100%;
  // background: blue;
  line-height: 4rem;

  &:before {
    vertical-align: middle;
  }

  &:hover {
    color: $color-text-highlight;
  }
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .trigger-search {
    display: inline-block;
  }
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .navigation-wrapper {
    transition: all 0.25s ease-in-out;
    transform-style: preserve-3d;

    .navigation {
      transform: translateZ(4rem/2);
    }

    .searchbox {
      transform: rotateX(90deg) translateZ(4rem*1.5);
    }

    &.has-search-visible {
      transform: rotateX(-89deg);
    }
  }
}

@include mq($from: tablet, $until: tabletoffset) {
  .mainNav {
    margin-left: 0;
  }

  .mainNav__link {
    margin: 0 0.5rem 0 0;
  }

  .trigger-search {
    position: absolute;
    right: -15px;
  }
}
