// Tables
//––––––––––––––––––––––––––––––––––––––––––––––––––

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid $light-grey;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}
