// Spacing
//––––––––––––––––––––––––––––––––––––––––––––––––––

button,
.button {
  margin-bottom: 1rem;
}

input,
textarea,
select,
fieldset {
  margin-bottom: 1.5rem;
}

pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
  margin-bottom: 2.5rem;
}
