.bgimage {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  // width: 100%;
  text-align: right;
  display: none;
  background-position: center right;
  width: calc(100% - 900px - 8%);
  background-size: cover;

  & > div,
  img {
    height: 100%;
    width: auto;
  }
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .bgimage {
    display: block;
  }
}

// @todo: hintergrund bei mobil ganz weg
