// Utilities
//––––––––––––––––––––––––––––––––––––––––––––––––––

.u-text-small {
  font-size: 0.85em;
}

.u-text-uppercase {
  text-transform: uppercase;
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .u-desktop-hidden {
    display: none !important;
  }
}

body:after {
  display: none;
  content: "wide";

  @include mq(mobile, phablet) {
    content: "phone";
  }

  @include mq(phablet, tablet) {
    content: "phablet";
  }

  @include mq(tablet, desktop) {
    content: "tablet";
  }

  @include mq(desktop, wide) {
    content: "desktop";
  }
}
