@import "globals";
@import "layout";
@import "header";

.gridbox {
  background-color: $color-background-box-light;
  padding: 3% 0;
  text-align: center;
  margin-bottom: 6.25%;
  position: relative;
}
