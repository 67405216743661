.box {
  background-color: $color-background-box-light;
  text-align: center;
  margin-bottom: 6.25%;
  position: relative;
  color: $color-text;
  text-decoration: none;
  display: block;
  width: 100%;
  background-size: cover;
  background-position: center center;

  &[href] {
    cursor: pointer;

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0);
      transition: background-color 333ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:hover {
      text-decoration: none;

      &:after {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .box__content {
    padding: 6.25%;
    -ms-word-break: break-all;
    // word-break: break-all;
    word-wrap: break-word;

    // Non standard for webkit
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  &.text-top {
    .box__marker {
      background: $color-text;
      color: $color-background;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
      text-transform: uppercase;
      padding: 1em;
      z-index: 20;

      & + .box__content {
        //margin-top: 3.6rem;
        padding-top: calc(6.25% + 3.6rem);
      }
    }
  }

  &.text-middle {
    //display: table;
    position: relative;

    .box__content {
      //display: table-cell;
      position: absolute;
      vertical-align: middle;
      //position: relative;
      z-index: 10;
      top: 50%;
      transform: translatey(-50%);
      width: 100%;

      *:last-child {
        margin-bottom: 0;
      }
    }

    .box__marker {
      background: $color-text;
      color: $color-background;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
      text-transform: uppercase;
      padding: 1em;
      z-index: 20;
    }
  }

  &.text-bottom {
    //display: table;
    position: relative;

    .box__content {
      //display: table-cell;
      position: absolute;
      vertical-align: middle;
      //position: relative;
      z-index: 10;
      top: 100%;
      transform: translatey(-100%);
      width: 100%;

      *:last-child {
        margin-bottom: 0;
      }
    }

    .box__marker {
      background: $color-text;
      color: $color-background;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
      text-transform: uppercase;
      padding: 1em;
      z-index: 20;
    }
  }

  &.dark-background {
    background-color: $color-background-box-dark;
    color: $color-background;
  }

  &.black-background {
    background-color: $color-text;
    color: $color-background;
  }

  h3 {
    margin-top: 0;
  }
}

.box--low {
  @extend .box;

  height: 150px;
}

.box--high {
  @extend .box;

  height: 390px;
}
