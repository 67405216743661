.searchbox {
  position: absolute;
  height: 100%;
  background: #fff;
  //z-index: -1;
  z-index: 10;
  //display: block;
  //top: 249px;
  width: 114%;
  left: -7%;
  display: none;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  transition-delay: 0.25s;

  label {
    position: absolute;
    top: -2rem;
    display: none;
  }

  // input[type="search"] {}

  .searchbox__form {}
  .searchbox__querygroup {}
  .searchbox__form__label {}

  .searchbox__form__query {
    margin-bottom: 0;
    padding-left: 6%;
  }

  .searchbox__actions {
    position: absolute;
    top: 0;
    right: 0;
  }

  .searchbox__form__submit {}

  .searchbox__form__reset {
    display: none;
  }
}

.has-nav-visible .searchbox {
  display: block;
  opacity: 1;
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .searchbox {
    top: 0;
    width: 100%;
    left: auto;
    display: block;

    label {
      display: block;
    }

    .searchbox__form__query {
      margin-bottom: 1.5rem;
      padding-left: 10px;
    }

    .searchbox__form__reset {
      display: inline-block;
    }
  }

  .has-nav-visible .searchbox {
    display: block;
    opacity: 1;
  }
}

// TEMPORARY!

.tx-indexedsearch-whatis,
.tx-indexedsearch-browsebox {
  display: none;
}

.tx-indexedsearch-res .tx-indexedsearch-res {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid $color-text-light;
}

.tx-indexedsearch-title {
  a {
    color: inherit;
  }
}

.tx-indexedsearch .tx-indexedsearch-res .tx-indexedsearch-descr .tx-indexedsearch-redMarkup {
  color: inherit;
}
