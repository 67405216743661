@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=b927b84d-3c15-484e-8a48-76282ded6e2f");

@font-face {
  font-family: "Avenir Next W00_n1";
  src: url("../ ../fonts/c0188ad0-247f-477d-9526-50b274232352.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/c0188ad0-247f-477d-9526-50b274232352.eot?#iefix");
  src: url(" ../fonts/c0188ad0-247f-477d-9526-50b274232352.eot?#iefix") format("eot"), url(" ../fonts/e752a633-50ba-41e9-a13a-5ebf7e6042e4.woff2") format("woff2"), url(" ../fonts/cf48de9f-77b6-4dd5-acd2-2b8801621202.woff") format("woff"), url(" ../fonts/8a62c8f4-04dc-45ea-b84f-7dddcd27afad.ttf") format("truetype"), url(" ../fonts/a9b6db18-6b5d-4209-8eef-cf22422bc83e.svg#a9b6db18-6b5d-4209-8eef-cf22422bc83e") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next W00_i1";
  src: url(" ../fonts/97a8c3a7-6ad9-4bfa-8d66-fa189155d194.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/97a8c3a7-6ad9-4bfa-8d66-fa189155d194.eot?#iefix");
  src: url(" ../fonts/97a8c3a7-6ad9-4bfa-8d66-fa189155d194.eot?#iefix") format("eot"), url(" ../fonts/92b18445-6b83-400f-ba60-387b0e0f3a6f.woff2") format("woff2"), url(" ../fonts/2b14f0a0-daea-4833-bf27-8af7874e7c7d.woff") format("woff"), url(" ../fonts/873f5dc2-2e3e-4caf-95d5-6006aae2f177.ttf") format("truetype"), url(" ../fonts/0491c9ef-b9bc-4528-8fc1-aaf0a0d8b992.svg#0491c9ef-b9bc-4528-8fc1-aaf0a0d8b992") format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Avenir Next W00_n2";
  src: url(" ../fonts/5db267f9-3612-485c-ae18-5698d2692816.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/5db267f9-3612-485c-ae18-5698d2692816.eot?#iefix");
  src: url(" ../fonts/5db267f9-3612-485c-ae18-5698d2692816.eot?#iefix") format("eot"), url(" ../fonts/174d458a-81e0-4174-9473-35e3bf0a613c.woff2") format("woff2"), url(" ../fonts/57a79aa3-9b06-4ba7-a9a4-2b766d826ecf.woff") format("woff"), url(" ../fonts/733cb7bd-50e1-4dee-893a-0b40ef382b02.ttf") format("truetype"), url(" ../fonts/594135c6-6c4f-4880-a0d2-ba923b5ef38e.svg#594135c6-6c4f-4880-a0d2-ba923b5ef38e") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next W00_i2";
  src: url(" ../fonts/9e4f77ac-2ea3-4f4e-b592-290f5c4af932.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/9e4f77ac-2ea3-4f4e-b592-290f5c4af932.eot?#iefix");
  src: url(" ../fonts/9e4f77ac-2ea3-4f4e-b592-290f5c4af932.eot?#iefix") format("eot"), url(" ../fonts/69a545eb-cdd0-4c00-9035-0029d8cede28.woff2") format("woff2"), url(" ../fonts/642cb581-067a-4f15-9df9-55c49c6b5446.woff") format("woff"), url(" ../fonts/972d8132-0f21-4d94-b42d-8f4022e6aa17.ttf") format("truetype"), url(" ../fonts/670d40f3-affd-4e16-8ad8-055f11470e24.svg#670d40f3-affd-4e16-8ad8-055f11470e24") format("svg");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Avenir Next W00_n4";
  src: url(" ../fonts/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix");
  src: url(" ../fonts/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix") format("eot"), url(" ../fonts/7db1f672-3a8f-4d19-9c49-7f61aed450b5.woff2") format("woff2"), url(" ../fonts/4ab86b35-c0c2-42b5-98ad-4b6eba66b197.woff") format("woff"), url(" ../fonts/276b3566-1c3b-4bc1-8915-15314f091f29.ttf") format("truetype"), url(" ../fonts/5d02f5f4-46e7-453a-aef9-3e7106d7bb68.svg#5d02f5f4-46e7-453a-aef9-3e7106d7bb68") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next W00_i4";
  src: url(" ../fonts/78f55966-cc8e-4f4c-bf8b-8fe59be9fe96.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/78f55966-cc8e-4f4c-bf8b-8fe59be9fe96.eot?#iefix");
  src: url(" ../fonts/78f55966-cc8e-4f4c-bf8b-8fe59be9fe96.eot?#iefix") format("eot"), url(" ../fonts/b17468ea-cf53-4635-984b-4d930a68ed4d.woff2") format("woff2"), url(" ../fonts/4d1d0d0d-9ea6-4117-901f-8b32ca1ab936.woff") format("woff"), url(" ../fonts/66b50093-e606-427c-a42a-a44b2f9ff219.ttf") format("truetype"), url(" ../fonts/78695677-7ebb-4ef8-8996-eff09dc64f26.svg#78695677-7ebb-4ef8-8996-eff09dc64f26") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Avenir Next W00_n5";
  src: url(" ../fonts/c6970a14-8b0f-4629-9072-71c7e123908f.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/c6970a14-8b0f-4629-9072-71c7e123908f.eot?#iefix");
  src: url(" ../fonts/c6970a14-8b0f-4629-9072-71c7e123908f.eot?#iefix") format("eot"), url(" ../fonts/b0b84e4d-2164-45c7-a674-1662f19f3ba6.woff2") format("woff2"), url(" ../fonts/e91d1bbf-3fea-45e2-b003-a22b12ce6e5f.woff") format("woff"), url(" ../fonts/ead8b64b-1abd-4d5b-a642-a21dfe2f463b.ttf") format("truetype"), url(" ../fonts/e536e1c2-92a4-4db4-8a41-1c55354d11b7.svg#e536e1c2-92a4-4db4-8a41-1c55354d11b7") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next W00_i5";
  src: url(" ../fonts/1f022c78-180e-4c6b-b5ee-e1573f17e4b6.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/1f022c78-180e-4c6b-b5ee-e1573f17e4b6.eot?#iefix");
  src: url(" ../fonts/1f022c78-180e-4c6b-b5ee-e1573f17e4b6.eot?#iefix") format("eot"), url(" ../fonts/349e5647-5161-46bb-a19f-8a609ae235e4.woff2") format("woff2"), url(" ../fonts/cc0a88c2-524b-4c90-b6f0-a80570222c30.woff") format("woff"), url(" ../fonts/03aefdc0-0198-4662-a9c7-640a4734063e.ttf") format("truetype"), url(" ../fonts/132a539d-37fa-48fb-92ec-1f4591f98ee1.svg#132a539d-37fa-48fb-92ec-1f4591f98ee1") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Avenir Next W00_n7";
  src: url(" ../fonts/fb4117a5-14ee-4035-8692-935bfff7fc2e.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/fb4117a5-14ee-4035-8692-935bfff7fc2e.eot?#iefix");
  src: url(" ../fonts/fb4117a5-14ee-4035-8692-935bfff7fc2e.eot?#iefix") format("eot"), url(" ../fonts/4c04fb14-abdb-4f93-82bb-734d1b84307b.woff2") format("woff2"), url(" ../fonts/04d2f223-2786-40c9-8481-be9dd47d8e7f.woff") format("woff"), url(" ../fonts/bce3274e-f498-48ae-b269-a6930df496e7.ttf") format("truetype"), url(" ../fonts/68f0375d-f66f-4f88-822a-5a0b61d2efc0.svg#68f0375d-f66f-4f88-822a-5a0b61d2efc0") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next W00_i7";
  src: url(" ../fonts/8316f326-afe6-49dc-a5d8-cd1d521182c2.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/8316f326-afe6-49dc-a5d8-cd1d521182c2.eot?#iefix");
  src: url(" ../fonts/8316f326-afe6-49dc-a5d8-cd1d521182c2.eot?#iefix") format("eot"), url(" ../fonts/395aeb70-3407-45d6-91b3-3d9a114af9bd.woff2") format("woff2"), url(" ../fonts/5f0686cb-9605-4656-8b38-9b8ca092ca9b.woff") format("woff"), url(" ../fonts/5fafc2a7-1ec9-4ba2-8d0b-836dfae0023b.ttf") format("truetype"), url(" ../fonts/f2ab6bd3-14da-4c86-949e-eddf030a8147.svg#f2ab6bd3-14da-4c86-949e-eddf030a8147") format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Avenir Next W00_n8";
  src: url(" ../fonts/ef4cad7e-cd09-4c64-ade9-5c99ffec032c.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/ef4cad7e-cd09-4c64-ade9-5c99ffec032c.eot?#iefix");
  src: url(" ../fonts/ef4cad7e-cd09-4c64-ade9-5c99ffec032c.eot?#iefix") format("eot"), url(" ../fonts/9590a83b-5072-43f4-b321-296d75e96d7d.woff2") format("woff2"), url(" ../fonts/fe3b4b6f-0633-4d79-8915-4fa24db0c0cc.woff") format("woff"), url(" ../fonts/2f549da8-c9f2-45e3-8fe0-8491cb395935.ttf") format("truetype"), url(" ../fonts/d7af93d6-04f7-48a4-88a5-6e1a5ba5754f.svg#d7af93d6-04f7-48a4-88a5-6e1a5ba5754f") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next W00_i8";
  src: url(" ../fonts/33c1c43b-a128-4e32-ac7d-d69c15f06ceb.eot?#iefix") format("eot");
}

@font-face {
  font-family: "Avenir Next W00";
  src: url(" ../fonts/33c1c43b-a128-4e32-ac7d-d69c15f06ceb.eot?#iefix");
  src: url(" ../fonts/33c1c43b-a128-4e32-ac7d-d69c15f06ceb.eot?#iefix") format("eot"), url(" ../fonts/36b39dbd-d105-4428-a201-87f710574b20.woff2") format("woff2"), url(" ../fonts/2c10ced5-1117-4e9d-9b19-69c72527ac7a.woff") format("woff"), url(" ../fonts/b01d2163-6813-48c3-b74f-641bfa1bd0ae.ttf") format("truetype"), url(" ../fonts/8fc17ee1-c73b-415b-b22a-825e0962f49b.svg#8fc17ee1-c73b-415b-b22a-825e0962f49b") format("svg");
  font-weight: 800;
  font-style: italic;
}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on November 19, 2015 */

@font-face {
  font-family: 'volkhovbold';
  src: url('../fonts/volkhov-bold-webfont.eot');
  src: url('../fonts/volkhov-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/volkhov-bold-webfont.woff2') format('woff2'), url('../fonts/volkhov-bold-webfont.woff') format('woff'), url('../fonts/volkhov-bold-webfont.ttf') format('truetype'), url('../fonts/volkhov-bold-webfont.svg#volkhovbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'volkhovbold_italic';
  src: url('../fonts/volkhov-bolditalic-webfont.eot');
  src: url('../fonts/volkhov-bolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/volkhov-bolditalic-webfont.woff2') format('woff2'), url('../fonts/volkhov-bolditalic-webfont.woff') format('woff'), url('../fonts/volkhov-bolditalic-webfont.ttf') format('truetype'), url('../fonts/volkhov-bolditalic-webfont.svg#volkhovbold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'volkhovitalic';
  src: url('../fonts/volkhov-italic-webfont.eot');
  src: url('../fonts/volkhov-italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/volkhov-italic-webfont.woff2') format('woff2'), url('../fonts/volkhov-italic-webfont.woff') format('woff'), url('../fonts/volkhov-italic-webfont.ttf') format('truetype'), url('../fonts/volkhov-italic-webfont.svg#volkhovitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'volkhovregular';
  src: url('../fonts/volkhov-regular-webfont.eot');
  src: url('../fonts/volkhov-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/volkhov-regular-webfont.woff2') format('woff2'), url('../fonts/volkhov-regular-webfont.woff') format('woff'), url('../fonts/volkhov-regular-webfont.ttf') format('truetype'), url('../fonts/volkhov-regular-webfont.svg#volkhovregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import "fontawesome/font-awesome";
