// Buttons
//––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button {
  display: inline-block;
  height: 4rem;
  padding: 0 1em;
  color: $color-background;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 4rem;
  letter-spacing: 0.1rem;
  text-transform: none;
  text-decoration: none;
  white-space: nowrap;
  background-color: $color-text;
  border-radius: 0;
  border: none;
  transition: all 333ms cubic-bezier(0.215, 0.61, 0.355, 1);

  .fa {
    font-size: 1.6rem;
    line-height: inherit;
  }
}

input {
  &[type="submit"],
  &[type="reset"],
  &[type="button"] {
    display: inline-block;
    height: 4rem;
    padding: 0 1em;
    color: $color-background;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 4rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    background-color: $color-text;
    border-radius: 0;
    border: none;
    transition: all 333ms cubic-bezier(0.215, 0.61, 0.355, 1);

    .fa {
      font-size: 1.6rem;
      line-height: inherit;
    }
  }
}

.button:hover,
button:hover {
  color: $color-text;
  background: $color-background-box-dark;
  border-color: none;
  outline: 0;
}

input {
  &[type="submit"]:hover,
  &[type="reset"]:hover,
  &[type="button"]:hover {
    color: $color-text;
    background: $color-background-box-dark;
    border-color: none;
    outline: 0;
  }
}

.button:focus,
button:focus {
  color: $color-text;
  background: $color-background-box-dark;
  border-color: none;
  outline: 0;
}

input {
  &[type="submit"]:focus,
  &[type="reset"]:focus,
  &[type="button"]:focus {
    color: $color-text;
    background: $color-background-box-dark;
    border-color: none;
    outline: 0;
  }
}

.button.button-primary,
button.button-primary {
  color: $color-background;
  background-color: $color-text;
  // border-color: $primary-color;
}

input {
  &[type="submit"].button-primary,
  &[type="reset"].button-primary,
  &[type="button"].button-primary {
    color: $color-background;
    background-color: $color-text;
    // border-color: $primary-color;
  }
}

.button.button-primary:hover,
button.button-primary:hover {
  color: $color-text;
  background: $color-background-box-dark;
  border-color: none;
  outline: 0;
}

input {
  &[type="submit"].button-primary:hover,
  &[type="reset"].button-primary:hover,
  &[type="button"].button-primary:hover {
    color: $color-text;
    background: $color-background-box-dark;
    border-color: none;
    outline: 0;
  }
}

.button.button-primary:focus,
button.button-primary:focus {
  color: $color-text;
  background: $color-background-box-dark;
  border-color: none;
  outline: 0;
}
