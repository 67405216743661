.mainFooter {
  background: $color-text;
  color: $color-background;
  font-size: 1.4rem;
  position: relative;
  padding: 3rem 0;

  a, a.u-slide-line {
    color: inherit;
    text-decoration: none;

    &:after {
      background-color: $color-background;
    }
  }

  p {
    margin-bottom: 0;
  }

  .container {
    background: $color-text;
  }

  // .footer__info {}

  .footer__info__block {
    float: left;
    margin-right: 3%;

    @include mq($until: tablet) {
      display: block;
      float: none;
      margin-bottom: 1rem;
    }
  }

  .footer__nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin: 0;
      padding: 0;
    }
  }
  // .footer__meta-nav {}

  .footer__meta-nav__link {
    // display: block;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.85em;
    cursor: pointer;
  }
}
