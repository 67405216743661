a.u-slide-line, .vbcn-customize.u-slide-line {
  text-decoration: none;
  position: relative;
  height: 100%;
  color: #fff; // $color-link;
  display: inline-block;
  overflow: hidden;

  @include mq($from: tablet) {
    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff; // $color-link;
      visibility: hidden;
      transform: translate3d(-100%, 0, 0);
      transition: visibility 0ms linear 333ms, transform 333ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:hover {
      &:after {
        visibility: visible;
        transform: translate3d(0%, 0, 0);
        transition: visibility 0ms linear, transform 333ms cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

a {
  text-decoration: none;
  color: $color-link;
  transition: all 333ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }
}
