// Base Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––
// NOTE
// html is set to 62.5% so that all the REM measurements throughout Skeleton
// are based on 10px sizing. So basically 1.5rem = 15px :)

html {
  font-size: 62.5%;
}

body {
  font-size: 1.5em; // currently ems cause chrome bug misinterpreting rems on body element
  line-height: 1.6;
  font-weight: 400;
  font-family: $font-family;
  color: $font-color;
}

// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $link-color;

  &:hover {
    color: darken($link-color, 5%);
  }
}

// Misc
//––––––––––––––––––––––––––––––––––––––––––––––––––

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid $light-grey;
}
