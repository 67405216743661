.main {
  // margin: $base-spacing-unit * 2;
  // background: #fff;
}

.container-wrapper {
  max-width: 1570px;
  margin: 0 auto;
  background: #fff;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.block {
  // margin-bottom: $column-margin;
  padding-top: $column-margin * 2;
  padding-bottom: $column-margin * 2;
  padding-left: 2.5%;
  padding-right: 2.5%;
}
