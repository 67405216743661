.be-icons-wrapper {
  text-align: right;
  display: none;
  perspective: 800px;
  position: relative;

  .be-icon {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    text-align: center;
    transform-style: preserve-3d;
    transition: 333ms;
    position: relative;

    .be-icon__image {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      backface-visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3D(-50%, -50%, 0);

      &.back {
        transform: translate3D(-50%, -50%, 0) rotateX(180deg);
      }
    }

    &.flipped {
      transform: rotateX(180deg);
    }
  }
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .be-icons-wrapper {
    display: block;
  }
}

//
//#be-icons-wrapper {
//}
//
//.be-icon {
//  transform-style: preserve-3d;
//  transition: transform 1s;
//}
//
//.be-icon .be-icon__image {
//  backface-visibility: hidden;
//}
//
//.be-icon__image.back {
//}
//
//.be-icon.flipped {
//  /* transform: rotateX( 180deg ); */
//}
//
//img.be-icon__image.front {
//  background: red;
//}
//
//img.be-icon__image.back {
//  background: blue;
//}
