body {
  // padding: $base-spacing-unit * 2;
  // overflow: hidden;
  background: white;
}

.main {
  // height: 100%;
  // max-height: 100%;
  // overflow: scroll;
  position: relative;

  & > .container {
    padding-top: 2%;
    padding-bottom: 6%;
    min-height: calc(100vh - 165px - 2% - 6%);
  }
}

audio,
canvas,
iframe,
img,
svg,
video {
  max-width: 100%;
}

.container {
  padding: 0 6%;
  background: #fff;
  margin: 0;
  width: 100%;
}

//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  .container {
    //box-sizing: content-box;
    padding: 0 4%;
    max-width: calc(#{$container-width} + 8%);
  }

  .main > .container:not(.no-min-height) {
    padding-top: 3rem;
    padding-bottom: 2rem;
    min-height: calc(100vh - 165px - 5rem - 2rem);
  }
}

.next-section {
  display: block;
  text-align: center;
  margin-top: 2.5%;
  margin-bottom: 0;
}

.block {
  margin-bottom: $column-margin;
}

.block__headline {

}

a.back-to-top-link {
  font-weight: bold;
  position: fixed;
  left: calc(860px + 8%);
  bottom: 0;
  padding: 10px;
  background-color: $color-text;
  display: block;
  visibility: visible;
  opacity: 0;
  transition: 0.4s;
  width: 40px;
  height: 40px;
  text-align: center;
  color: $color-background;
  z-index: 999;

  @media (max-width: 992px) {
    left: calc(100% - 40px);
    bottom: 80px;
  }

  &:hover {
    text-decoration: none;
    background: $color-background-box-dark;
  }
}

.container.no-padding {
  padding: 0;
}

.mainheadline {
  margin-top: 106px;
}

.caption {
  display: block;
  margin-top: 1em;
}

/* stylelint-disable */
.slick-arrow {
  background: #000 !important;
  padding: 10px !important;
  width: 44px !important;
  height: 44px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;


  &.slick-prev {
    left: 0;
    padding-left: 17px !important;
  }

  &.slick-next {
    right: 0;
    padding-left: 17px !important;
  }
}
/* stylelint-enable */
