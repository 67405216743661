body {
  font-size: 1.6em;
  line-height: 1.375;
  font-weight: 500;
  font-family: $font-family;
  color: $color-text;
  letter-spacing: 0.1rem;
}

// h1 {
//   font-size: 5.4rem;
//   letter-spacing: 0.375em;
// }
//
// h2 {
//   font-size: 1.375em;
// }

// Typography
//––––––––––––––––––––––––––––––––––––––––––––––––––

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 700;
}

h1 {
  font-size: 2.2rem;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  // text-transform: uppercase;
  padding: 2rem 0;
  border-top: 1px solid $color-text-light;
  border-bottom: 1px solid $color-text-light;

  // &.layout-border {
  //  border-top: 1px solid $color-text-light;
  //  border-bottom: 1px solid $color-text-light;
  // }
}

#p29 {
  h1 {
    font-size: 4rem;
    line-height: 1.2;
    // text-transform: uppercase;
    border: 0;
  }
}

h2 {
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: 0.1rem;
  padding: 1.2rem 0;
}

h3 {
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: normal;
}

h4 {
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: normal;
  font-weight: 400;
}

h5 {
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: normal;
}

h6 {
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: normal;
  font-weight: 400;
}

// Larger than phablet
//@media (#{$bp-larger-than-phablet}) {
@include mq($from: tablet) {
  // TODO: SET THEM
  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 2rem;
    padding: 2rem 0;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.6rem;
  }

  h5 {
    font-size: 1.4rem;
  }

  h6 {
    font-size: 1.4rem;
  }
}

p {
  margin-top: 0;
}

b {
  font-weight: 700;
}
