.vbcn-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0.7;
}

.vbcn {
  @include vbcn-base-style;

  //font-family: 'Times New Roman';
  font-size: 15px;
  line-height: 1.3;
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 100%;
  padding: 1.4em;
  max-width: 44em;
  box-sizing: border-box;
  z-index: 101;

  @media (max-width: 768px) {
    max-width: 50em;
    right: unset;
    width: 98%;
    margin: 0 1%;
    bottom: 0;
  }

  &.is-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
    overflow: auto;
    max-height: 100vh;

    .vbcn-buttons {
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }

  a {
    color: currentColor;
    text-decoration: underline;

    &:hover {
      color: $color-highlight;
    }
  }

  .vbcn-details-view {
    display: none;
  }

  .vbcn-button {
    &#vbcn-accept-all {
      display: none;
    }

    &#vbcn-decline-all {
      display: none;
    }

    &#vbcn-cancel-customize {
      display: none;
    }

    &#vbcn-save {
      display: none;
    }
  }

  &.is-config-mode {
    .vbcn-preset-view {
      display: none;
    }

    .vbcn-details-view {
      display: block;
    }

    .vbcn-button {
      &#vbcn-accept-preset {
        display: none;
      }

      &#vbcn-decline {
        display: none;
      }

      &#vbcn-customize {
        display: none;
      }

      &#vbcn-accept-all {
        display: inline-block;
      }

      &#vbcn-decline-all {
        display: inline-block;
      }

      &#vbcn-cancel-customize {
        display: inline-block;
      }

      &#vbcn-save {
        display: inline-block;
      }
    }
  }
}

.vbcn-title {
  //font-family: 'Arial';
  font-size: 1.8em;
  margin: 0 0 0.6em;
  border-bottom: 1px solid $color-text-dimmed;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
}

.vbcn-text {
  margin: 0 0 1.2em;
}

.vbcn-option {
  position: relative;
  display: block;
  padding-left: 1.6em;
  margin: 0 0 1em;
  user-select: none;
  cursor: pointer;
}

.vbcn-option-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;

  &:checked ~ .vbcn-option-checkmark {
    background: $color-highlight;
    border-color: $color-highlight;

    &:after {
      color: #fff;
      content: '×';
    }
  }
}

.vbcn-option-checkmark {
  position: absolute;
  top: 0.075em;
  left: 0;
  height: 1.2em;
  width: 1.2em;
  background: transparent;
  border: 1px solid rgba($color-text, 0.5);

  &:after {
    text-align: center;
    line-height: 1;
    display: block;
  }
}

.vbcn-option-desc {
  @include vbcn-text-small;

  display: block;
}

.vbcn-option-mandatory-text {
  color: $color-error;
  font-weight: bold;
}

.vbcn-buttons {
  text-align: right;
}

.vbcn-button {
  @include vbcn-button;
}
