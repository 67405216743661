.divider {
  position: relative;

  .divider__line {
    width: 10rem;
    border-bottom: 0.6rem $color-text solid;
    position: absolute;
    left: -2rem;
    top: -0.3rem;
  }
}
