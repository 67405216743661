// Typography
//––––––––––––––––––––––––––––––––––––––––––––––––––

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}

h1 {
  font-size: 4rem;
  line-height: 1.2;
  letter-spacing: -0.1rem;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}

h3 {
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: -0.1rem;
}

h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -0.08rem;
}

h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.05rem;
}

h6 {
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0;
}

// Larger than phablet
@media (#{$bp-larger-than-phablet}) {
  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 4.2rem;
  }

  h3 {
    font-size: 3.6rem;
  }

  h4 {
    font-size: 3rem;
  }

  h5 {
    font-size: 2.4rem;
  }

  h6 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
}
