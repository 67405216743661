.branding {
  float: left;
}

.mainNav {
  height: 5em;
  line-height: 5em;
}

.mainNav__link {
  margin: 0 $base-spacing-unit;
  color: $color-nav-link;
}
